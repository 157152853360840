import _three from "three";
var exports = {};
var THREE = _three;
console.warn("WARNING: The 'three.js' npm package is deprecated in favor of the 'three' npm package, please upgrade.");
{
  if (exports) {
    exports = exports = THREE;
  }

  exports.THREE = THREE;
}
export default exports;
const _THREE = exports.THREE;
export { _THREE as THREE };